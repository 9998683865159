import React from "react";
export const InputBox = ({
  label,
  onChange,
  name,
  placeholder,
  form,
  value,
  readOnly,
}) => (
  <div class="input-container mt-3 mx-1">
    <input
      id="Username"
      placeholder={placeholder}
      onChange={onChange}
      name={name}
      value={form[name]}
      class="input-field"
      type="text"
    />
  </div>
);

const Mapper = {
  default: InputBox,
};

export const Input = (props) => {
  const Component = Mapper[props.type] || Mapper.default;
  return <Component {...props} />;
};

Input.defaultProps = {
  type: "default",
};
