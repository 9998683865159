import React from "react";
import { Helmet } from "react-helmet";

const SchemaScript = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "School",
          name: "Hermann Gmeiner School Faridabad",
          alternateName: "Hermann Gmeiner School",
          url: "https://hermanngmeinerschoolfaridabad.org/",
          logo: "https://hermanngmeinerschoolfaridabad.org/",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "0129-4088574",
            contactType: "technical support",
            contactOption: "TollFree",
            areaServed: "IN",
            availableLanguage: ["en", "Hindi"],
          },
          sameAs: [
            "https://www.facebook.com/hermann.gmeiner.9279",
            "https://www.instagram.com/hermann_gmeiner_school_fbd/?hl=en",
            "https://www.youtube.com/@hermanngmeinerschoolfarida1969",
            "https://hermanngmeinerschoolfaridabad.org/",
            "https://x.com/HGS_Faridabad",
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SchemaScript;
