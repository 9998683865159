import React from "react";
import "react-step-progress-bar/styles.css";
import { Step, ProgressBar } from "react-step-progress-bar";
import { ALL_STEPS_LIST } from "./contants";
import "./progress.css";

const getStepPosition = (currentStep) => {
  return ALL_STEPS_LIST.findIndex((step) => step === currentStep) + 1;
};

export const Progress = ({ currentStep }) => (
  <ProgressBar
    width={500}
    percent={100 * (getStepPosition(currentStep) / ALL_STEPS_LIST.length)}
    filledBackground="#0076BD"
    height={2}
  >
    {ALL_STEPS_LIST.map((_, index, arr) => {
      return (
        <Step
          position={100 * (index / arr.length)}
          transition="scale"
          children={({ accomplished }) => (
            <div
              className="flex-center progress-multistep"
              style={{
                color: accomplished ? "#fff" : "#000",
                background: accomplished ? "#0076BD" : "#e5e5e5",
              }}
            >
              {accomplished && index + 1}
              {!accomplished && (
                <div
                  className="white-bg flex-center"
                  style={{
                    background: accomplished ? "#0076BD" : "#e5e5e5",
                  }}
                >
                  {index + 1}
                </div>
              )}
            </div>
          )}
        />
      );
    })}
  </ProgressBar>
);
