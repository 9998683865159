import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import Footer from "../Footer/Footer";
import GetInTouch from "../GetInTouch/GetInTouch";
import Inspired from "../Inspired/Inspired";
import Landing from "../Landing/Landing";
import MenuSection from "../MenuSection/MenuSection";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import PhotoGallery from "../PhotoGallery/PhotoGaller";
import ReactGA from "react-ga";
//
import "./Home.css";
import HomeModal from "../HomeModal/HomeModal";
import ResultMoving from "../ResultMoving/ResultMoving";
import NewNavbar from "../NavBar/NewNavbar";
import SchemaScript from "./SchemaScript";
import FAQAccordion from "./FAQAccordian";
function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const [isModal, setIsModal] = useState(true);
  const handleScroll = (e) => {
    console.log(e);
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
  };

  const handleClose = () => {
    setIsModal(false);
  };

  return (
    <div className="home_main" onScroll={handleScroll}>
      <SchemaScript />
      <NewNavbar handleClick={handleClick} />
      {/* <Navbar isScrolled={isScrolled} handleClick={handleClick} /> */}
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={true} />
      <ResultMoving />
      <p className="heading-small">
        Hermann Gmeiner School in Faridabad proudly excels in delivering
        educational excellence, with cutting-edge facilities, attuned to the
        evolving needs of generation since 1986 . Our institution, equipped with
        ATL, STEM, and Computer labs, aims to be the best CBSE schools in
        Faridabad. We take pride in being the guiding force, upskilling and
        uplifting each child at the primary level, instilling in them the
        determination to be torchbearers of their own success and brilliance.
      </p>
      <MenuSection />
      <Inspired />
      <GetInTouch />
      <FAQAccordion />
      <PhotoGallery />
      <Footer />
      <HomeModal show={isModal} onHide={handleClose} />
    </div>
  );
}







export default Home;
