
import Marquee from "react-fast-marquee";
import './ResultMoving.css'

const ResultMoving = () => {
    return (
        <Marquee>
            <p> <span className="mx-2">★★★</span> Hermann Gmeiner School, Faridabad - Class X Results 2022-23 (100% Pass) ● Riddhishree Nayak (95.4%) ● Madhav Sarawat (91.8%) ● Mustafa (91.4%) ● Kajal (90.2%) <span className="mx-2">★★★</span> </p>
            <p> Hermann Gmeiner School, Faridabad - Class Xll Results 2022-23 (100% Pass) ● Jamil Ahamad (95.4%) ● Kashish (90%) ● Mansi (88.4%)</p>
        </Marquee>
    )
}
export default ResultMoving