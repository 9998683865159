import React, { useLayoutEffect, useRef, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import { disclosure_data_documents, disclosure_data_general, disclosure_data_results, disclosure_data_results_10, disclosure_data_results_12, disclosure_data_school, disclosure_data_staff } from "../../variable";

//
import "./MandatoryDisclosures.css";
import NewNavbar from "../NavBar/NewNavbar";

function MandatoryDisclosures() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    console.log(e);
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };
  return (
    <div className="home_main" onScroll={handleScroll}>
      <NewNavbar handleClick={handleClick} />
      {/* <Navbar isScrolled={true} handleClick={handleClick} /> */}
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <div style={{ marginTop: "100px", marginLeft: "25px", fontSize: "20px", fontWeight: "700" }}>MANDATORY PUBLIC DISCLOSURE</div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>A. GENERAL INFORMATION</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_general.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={6}>{item.name}</Col>
            <Col className="mandatory_col_last" lg={5}>{item.description}</Col>
          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>B. DOCUMENTS & INFORMATION</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_documents.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={6}>{item.name}</Col>

            {item.id === 1 ?
              <Col className="mandatory_col_last" lg={5}>{item.description}</Col>

              :

              <Col className="mandatory_col_last" lg={5}>
                <a href={item.customcomponent} target="_blank">
                  <Button
                    className="man_button"
                  >
                    View
                  </Button>
                </a>
              </Col>
            }


          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>C. RESULTS & ACADEMICS</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_results.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={6}>{item.name}</Col>
            {item.id === 1 ?
              <Col className="mandatory_col_last" lg={5}>{item.description}</Col>

              :

              <Col className="mandatory_col_last" lg={5}>
                <a href={item.customcomponent} target="_blank">
                  <Button
                    className="man_button"
                  >
                    View
                  </Button>
                </a>
              </Col>
            }
          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>D. STAFF (TEACHING)</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_staff.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={6}>
              <div>{item.name}</div>
              <div>{item.name1}</div>
              <div>{item.name2}</div>
              <div>{item.name3}</div>
            </Col>
            <Col className="mandatory_col_last" lg={5}>
              <div>{item.description}</div>
              <div>{item.description1}</div>
              <div>{item.description2}</div>
              <div>{item.description3}</div>
            </Col>
          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>RESULT CLASS: X</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_results_10.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={2}>{item.extra_title}</Col>
            <Col className="mandatory_col" lg={3}>{item.name}</Col>
            <Col className="mandatory_col" lg={3}>{item.description}</Col>
            <Col className="mandatory_col" lg={2}>{item.description2}</Col>
            <Col className="mandatory_col_last" lg={1}>{item.extra_description}</Col>
          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>RESULT CLASS: XII</div>
      <div className="mandatory_table" style={{ display: "flex", flexDirection: "column" }}>
        {disclosure_data_results_12.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={2}>{item.extra_title}</Col>
            <Col className="mandatory_col" lg={3}>{item.name}</Col>
            <Col className="mandatory_col" lg={3}>{item.description}</Col>
            <Col className="mandatory_col" lg={2}>{item.description2}</Col>
            <Col className="mandatory_col_last" lg={1}>{item.extra_description}</Col>
          </Row>
        ))}
      </div>
      <div className="mandatory_heading" style={{ fontSize: "20px", fontWeight: "700" }}>E. SCHOOL INFRASTRUCTURE</div>
      <div className="mandatory_table" style={{ marginBottom: "25px", display: "flex", flexDirection: "column" }}>
        {disclosure_data_school.map((item, index) => (
          <Row className="mandatory_list" style={{ width: "80%" }}>
            <Col className="mandatory_col" lg={1}>{item.title}</Col>
            <Col className="mandatory_col" lg={6}>{item.name}</Col>
            <Col className="mandatory_col_" lg={5}>
              <div>{item.description}</div>
              <div>{item.description1}</div>
              <div>{item.description2}</div>
              <div>{item.description3}</div>
              <div>{item.description4}</div>
              <div>{item.description5}</div>
              <div>{item.description6}</div>
            </Col>
          </Row>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default MandatoryDisclosures;